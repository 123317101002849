<template>
	<div :class="[
		'highlight-bar',
		(color) ? 'highlight-bar--' + color : '',
		(alignCenter) ? 'highlight-bar--align-center' : '',
		(disabled) ? 'highlight-bar--disabled' : ''
	]">
		<div class="highlight-bar__icon-title">
			<MaterialDesignIcon v-if="valueIcon === false && icon" :icon="icon" :color="(color) ? 'light' : ''" />
			<span class="highlight-bar__value-icon" v-if="valueIcon === true">{{ value }}</span>
			<span class="highlight-bar__title">{{ title }}</span>
		</div>
		<span v-if="valueIcon === false">{{ value }}</span>
	</div>
</template>

<script>
import { defineComponent } from 'vue';
import MaterialDesignIcon from "@/view/components/MaterialDesignIcon.vue";

export default defineComponent({
	components: {
		MaterialDesignIcon
	},
	name: 'DataBar',
	props: {
		alignCenter: {
			default: false,
			type: Boolean
		},
		color: {
			type: String
		},
		icon: {
			default: '',
			type: String
		},
		title: {
			default: '',
			type: String
		},
		value: {
			type: Number
		},
		valueIcon: {
			default: false,
			type: Boolean
		},
		disabled: {
			default: false,
			type: Boolean
		}
	}
});
</script>

<style lang="scss" scoped>
.highlight-bar {
	cursor: pointer;
	align-items: center;
	background-color: var(--ion-color-light);
	color: var(--ion-color-primary);
	display: flex;
	flex-direction: row;
	font-size: 18px;
	gap: 5px;
	justify-content: space-between;
	padding: 10px;

	&__icon-title {
		align-items: center;
		display: flex;
		gap: 10px;
	}

		&__value-icon {
			font-weight: 700;
		}

	&--align-center {
		justify-content: center;
	}

	&--disabled {
		opacity: 0.5;
		pointer-events: none;
	}

	&--danger,
	&--primary,
	&--success {
		color: var(--ion-color-light);
		font-weight: 700;
	}

		&--danger {
			background-color: var(--ion-color-danger);
		}

		&--primary {
			background-color: var(--ion-color-primary);
		}

		&--success {
			background-color: var(--ion-color-success);
		}
}
</style>